import { useEffect, useState } from 'react';
import HeaderBanner from '../../_common/headers/HeaderBanner/HeaderBanner';
import KeepGrowingCarousel from '../../_common/programs/KeepGrowingCarousel/KeepGrowingCarousel';
import MyProgramsGrid from './MyProgramsGrid/MyProgramsGrid';

function MyProgramsView() {
  const [myPrograms, setMyPrograms] = useState([]);

  useEffect(() => {    
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  return (
    <>
      <article className='app-has-navbar'>
        <section>
          <HeaderBanner title="Mis Programas" />
        </section>        
        <section className='app-quaternary-bg'>
          <MyProgramsGrid />
        </section>        
        <section>
          <KeepGrowingCarousel />
        </section>
      </article>
    </>
  );
}

export default MyProgramsView;
