import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import RBBreadcrumb from 'react-bootstrap/Breadcrumb';

function Breadcrumb({
  breadcrumbs
}) {

  // #region Vars and Consts

  // const nav = useNav();
  // const { id } = useParams();
  // const { viewport } = useContext(AppContext);
  // const { user, login, logout, hasPermission } = useAuth();  
  // const [myConst, setMyConst] = useState([]);

  // #endregion

  // #region Events

  useEffect(() => {

  }, []);

  const onEvent = (param) => {

  };

  // #endregion

  // #region Methods

  const myMethod = (param) => {

  };

  // #endregion

  // #region Templates

  const linkBreadcrumbItemTemplate = (breadcrumb) => {
    return (
      <>
        <RBBreadcrumb.Item
          href={breadcrumb.navPath}          
        >
          <span className='app-montserrat-medium-font app-primary-text'>
            {breadcrumb.name}
          </span>
        </RBBreadcrumb.Item>
      </>
    )
  };

  const activeBreadcrumbItemTemplate = (breadcrumb) => {
    return (
      <>
        <RBBreadcrumb.Item          
          active
        >
          <span className='app-montserrat-medium-font app-primary-text'>
            {breadcrumb.name}
          </span>
        </RBBreadcrumb.Item>
      </>
    )
  };

  const breadcrumbItemTemplate = (breadcrumb, lastBreadcrumb) => {
    return (
      <>
        {
          !lastBreadcrumb ?
            linkBreadcrumbItemTemplate(breadcrumb) :
            activeBreadcrumbItemTemplate(breadcrumb)
        }
      </>
    );
  };

  const breadcrumbTemplate = (
    <>      
      <RBBreadcrumb>                
        {
          (breadcrumbs && breadcrumbs.length > 0) && (
            breadcrumbs.map((breadcrumb, index) => {
              const lastBreadcrumb = index === breadcrumbs.length - 1
              return breadcrumbItemTemplate(breadcrumb, lastBreadcrumb);
            })
          )
        }
      </RBBreadcrumb>
    </>
  );

  // #endregion

  return (
    <>
      <article>
        <section>
          {breadcrumbTemplate}
        </section>
      </article>
    </>
  );
}

export default Breadcrumb;
