import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import * as Paths from "../../../../../../constants/paths";
import { useAuth } from '../../../../../../hooks/useAuth';
import { useNav } from '../../../../../../hooks/useNav';
import MyProgramGridItem from './MyProgramGridItem/MyProgramGridItem';

function MyProgramsGrid() {

  // #region Vars and Consts

  const nav = useNav();
  const { user, logout } = useAuth();
  const [myPrograms, setMyPrograms] = useState([]);
  
  // #endregion

  // #region Events

  useEffect(() => {
    getMyPrograms();
  }, []);

  const onProgramPressed = (program) => {
    goToProgram(program);
  };

  // #endregion

  // #region Methods
  
  const getMyPrograms = () => {
    fetch(`${process.env.REACT_APP_API_URL}/lagranvida/v1/user/getPrograms?userId=${user.userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      }
    }).then(response => response.json()).then(data => {
      if (data.responseCode === 'OK') {
        console.info('My programs:', data);
        setMyPrograms(data.responseObject);
      } else if (data.status === 403) {
        // Session expired
        logout();
      }
    }, (error) => {
      console.error('Error getting my programs', error);
      // TODO: Show error message to the user
    });    
  };

  const goToProgram = (program) => {    
    nav.navigate(Paths.views.user.MY_PROGRAM, null, { ':id': program.programId });
  };

  // #endregion

  // #region Templates

  const gridItemTemplate = (program, index) => {
    return (
      <>
        <Col 
          key={index} 
          xs={12} 
          sm={12} 
          md={6} 
          lg={4} 
          xl={3}
          className='p-3'
        >
          <MyProgramGridItem 
            image={program.imageUrl}
            title={program.name}
            progress={program.percentageProgress}
            completed={program.completed}
            onImagePressed={() => { onProgramPressed(program) }}
          />
        </Col>
      </>
    )
  };

  const gridTemplate = (
    <>
      <Row>
        {
          (myPrograms && myPrograms.length > 0) && (
            myPrograms.map((program, index) => {
              return gridItemTemplate(program, index);
            })
          )
        }
      </Row>
    </>
  );

  // #endregion
  
  return (
    <>
      <article>        
        <Container>          
          {gridTemplate}
        </Container>   
      </article>  
    </>
  );
}

export default MyProgramsGrid;
