import { Col, Container, Row } from 'react-bootstrap';
import aboutUsWayImage from '../../../../../../assets/images/about-us/la-gran-vida-nosotros-camino.png';
import aboutUsCoupleImage from '../../../../../../assets/images/about-us/la-gran-vida-nosotros-couple.png';
import underlinePurpleIamge from '../../../../../../assets/images/underlines/underline-purple.png';
import * as Paths from "../../../../../../constants/paths";

function AboutUsDetailsBox() {

  // #region Templates

  const detailsTemplate = (
    <>
      <Container className='p-5'>
        <Row>
          <Col xs={12} lg={7}>
            <h1
              id={Paths.sections.aboutUs.WHAT_DEFINE_US}
              className='app-pharma-bold-font app-primary-text text-uppercase'
              style={{
                fontSize: '2.5rem'
              }}
            >
              Lo que nos define.
            </h1>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              <b className='app-secondary-text'>Vivimos</b> el Respeto, Valoramos y validamos en nosotros mismos y en los demás sus capacidades, puntos de vista y elecciones desde el entendimiento que el mundo en el que vivo es mi creación.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              <b className='app-secondary-text'>Generamos Relaciones valiosas</b>, las mantenemos y somos recíprocos en relaciones que aportan a nuestra vida desde el amor y respeto.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              <b className='app-secondary-text'>La Responsabilidad es nuestra guía</b> en las elecciones que realizamos. Vivimos la responsabilidad afectiva, en mis puntos de vista y las consecuencias que derivan de mis actos.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              <b className='app-secondary-text'>Amamos la vida</b>, Aceptamos la vida tal como es y a nosotros mismo como somos hoy. Nos damos cuenta de que está en mí, lograr la vida que deseo.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              <b className='app-secondary-text'>Somos Accesibles</b> y buscamos que nuestra contribución sea de accesible para todos aquellos que desean para sí mismos una transformación positiva y amorosa en su realidad. Compartimos y colaboramos.
            </p>
            <p className='app-montserrat-medium-font app-secondary-text fs-4'>
              <i>
                "La lealtad no se puede imprimir. No puede ser producida en una línea de montaje, porque su origen está en el corazón humano, el centro del respeto a uno mismo y de la dignidad humana. Es una fuerza que existe solo cuando se dan las condiciones exactas y es una fuerza muy sensible a la traición."
              </i>
            </p>
            <p className='app-pharma-bold-font app-primary-text fs-4 text-uppercase text-end'>
              Maurice Franks
            </p>
          </Col>
          <Col xs={12} lg={5}>
            <img
              className='w-100'
              src={aboutUsCoupleImage}
            />
          </Col>
        </Row>       
      </Container>
      <Container fluid className='app-primary-bg'>
        <Row>
          <Col xs={12} lg={{span:6, offset:1}}>
            <img
              className='w-100'
              src={aboutUsWayImage}
            />
          </Col>
          <Col xs={12} lg={4}>



            <h2
              id={Paths.sections.aboutUs.WHAT_DO_WE_DO}
              className='app-neutral-black-text pt-4 app-suomi-hand-script-font'
              style={{
                fontSize: '3rem'
              }}
            >
              ¿Qué hacemos?
            </h2>
            <img
              className='w-25'
              src={underlinePurpleIamge}
            />
            <p className='app-montserrat-medium-font app-neutral-black-text fs-4'>
              Facilitamos y habilitamos a todos aquellos que lo deseen una serie de recursos (talleres, conferencias, libros y programas) que sirven como guía para acceder al universo de las infinitas posibilidades, que les permitirá ser la versión ilimitada de sí mismos, de manera que puedan actualizarse y renovarse en el momento que así decidan.
            </p>

            <p className='app-montserrat-medium-font app-neutral-black-text fs-4'>
              <i>
                "Cualquier vida, no importa lo compleja que sea, está hecha de un solo momento. El momento en que un hombre descubre de una vez y para siempre, quién es."
              </i>
            </p>

            <p className='app-pharma-bold-font app-neutral-black-text fs-4 text-uppercase text-end'>
              Jorge Luis Borges​
            </p>



          </Col>
        </Row>
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article className='app-quaternary-bg'>
        <section>
          {detailsTemplate}
        </section>
      </article>
    </>
  );
}

export default AboutUsDetailsBox;