import { useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import underlinePurpleIamge from '../../../../../../assets/images/underlines/underline-purple.png';
import * as Paths from "../../../../../../constants/paths";
import * as Viewports from "../../../../../../constants/viewports";
import { useAuth } from '../../../../../../hooks/useAuth';
import { useNav } from '../../../../../../hooks/useNav';
import SignInDialog from '../../../../../_common/signIn/SignInDialog/SignInDialog';

function InvestmentBox() {

  // #region Vars and Consts
  
  const nav = useNav();
  const { hasPermission, user } = useAuth();
  const [showSignInDialog, setShowSignInDialog] = useState(false);
  const [signInDialogErrorMessage, setSignInDialogErrorMessage] = useState('');

  // #endregion

  // #region Events

  const onSignUpPress = () => {
    nav.navigate(Paths.views.SIGN_UP);
  };

  const onPay = () => {
    pay(
      user.name,
      user.lastName,
      user.email,
      1200
    );
  };

  const onHideLogin = () => {
    setShowSignInDialog(false);
  };

  // #endregion  

  // #region Methods

  const pay = (
    name,
    lastName,
    email,
    amount
  ) => {
    fetch(`${process.env.REACT_APP_API_URL}/lagranvida/v1/conekta/order`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      },
      body: JSON.stringify({
        name,
        lastName,
        email,
        amount
      }),
    }).then(response => response.json()).then(data => {
      console.log('Success al pagar:', data);
      if (data.responseCode === 'OK') {
        // window.location.href = data.responseObject;        
      } else if (data.status === 403) {
        // Session expired
        openSignIn('La sesión expiró, por favor inicie sesión nuevamente.');
      }
    }, (error) => {
      console.error('Error al pagar:', error);
    });
  };

  const openSignIn = (errorMessage) => {
    setSignInDialogErrorMessage(errorMessage);
    setShowSignInDialog(true);
  };

  // #endregion

  // #region Templates

  const investmentTemplate = (
    <>
      <Container className='p-5'>
        <h2 className='app-pharma-bold-font app-primary-text text-uppercase text-center py-3'>
          Duración e Inversión
        </h2>
        <Row className='pb-4'>
          <Col lg={4}>
            <Card className='my-2 app-tertiary-bg'>
              <Card.Body>
                <Card.Title>
                  <span className='app-montserrat-bold-font text-uppercase'>
                    &nbsp;
                  </span>
                  <h1 className='app-montserrat-bold-font text-center text-uppercase'>
                    6 semanas
                  </h1>
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className='my-2 app-primary-bg'>
              <Card.Body>
                <Card.Title className='position-relative text-center'>
                  <span className='app-montserrat-bold-font text-uppercase'>
                    Equivale a
                  </span>
                  <h1 className='app-montserrat-bold-font text-center text-uppercase'>
                    12 sesiones
                  </h1>
                  <h1
                    className='app-suomi-hand-script-font app-accent-text position-absolute'
                    style={{
                      fontSize: '2rem',
                      right: '20%',
                      bottom: '-25px'
                    }}
                  >
                    Al precio de una.
                  </h1>
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className='my-2 app-secondary-bg'>
              <Card.Body>
                <Card.Title className='position-relative'>
                  <h1 className='app-montserrat-bold-font app-primary-text text-center text-uppercase'>
                    $ 1,200&nbsp;
                    <span className='h4'>
                      mxp
                    </span>
                  </h1>
                  <img
                    className='w-50 position-absolute'
                    src={underlinePurpleIamge}
                    style={{
                      left: '25%',
                      bottom: '-20px'
                    }}
                  />   
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <p className='app-montserrat-medium-font app-neutral-white-text text-center fs-4'>
          Está diseñado para que lo puedas concluir en&nbsp;
          <span className='app-tertiary-text'>
            6 semanas
          </span>&nbsp;
          (una por módulo).
        </p>
        <p className='app-montserrat-medium-font app-neutral-white-text text-center fs-4'>
          La inversión es de&nbsp;
          <b className='app-primary-text'>
            1,200 MXP
          </b>,&nbsp;
          lo que equivale a recibir&nbsp;
          <b className='app-primary-text'>
            12 sesiones
          </b>&nbsp;
          terapéuticas por el precio de una.
        </p>
        <Button
          className="text-uppercase app-pharma-bold-font fs-3 p-3 shadow mx-auto d-block"
          variant='primary'
          size={Viewports.sizes.MEDIUM}
          onClick={onPay}
        >
          ¡Inscribete ya!
        </Button>
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article className='app-secondary-bg'>
        <section>
          {investmentTemplate}
        </section>
      </article>
      <SignInDialog        
        show={showSignInDialog}
        navTo={Paths.views.programs.FOURTY_DAYS_FOR_AN_AMAZING_LIFE}
        errorMessage={signInDialogErrorMessage}
        onHide={onHideLogin}
      />
    </>
  );
}

export default InvestmentBox;