import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import * as Program from "../../../../../../../constants/program";
import { useAuth } from '../../../../../../../hooks/useAuth';
import FormFieldImage from './FormFieldImage/FormFieldImage';
import FormFieldTable from './FormFieldTable/FormFieldTable';
import FormFieldText from './FormFieldText/FormFieldText';
import FormFieldTextBox from './FormFieldTextBox/FormFieldTextBox';
import FormFieldVideo from './FormFieldVideo/FormFieldVideo';
import FormFieldTextArea from './FormFieldTextArea/FormFieldTextArea';
import FormFieldSubtitle from './FormFieldSubtitle/FormFieldSubtitle';

function ModuleStep({
  index,
  moduleId,
  completed,
  stepNo,
  load,
  onFormChange
}) {

  // #region Vars and Consts

  // const nav = useNav();
  // const { id } = useParams();
  // const { viewport } = useContext(AppContext);
  const { user, login, logout, hasPermission } = useAuth();  
  const [step, setStep] = useState(null);
  const [form, setForm] = useState(null);

  // #endregion

  // #region Events

  useEffect(() => {
    if (
      load
      && moduleId !== null
      && stepNo !== null 
      && user 
    ) {
      getStep(user.userId, moduleId, stepNo);
    } 
  }, [
    moduleId,
    stepNo,
    load
  ]);

  const onTextBoxChange = (formDetailId, value) => {
    const _form = [...form]; 
    var index = _form.findIndex(formFiled => formFiled.formDetailId === formDetailId);
    if (index >= 0) {
      _form[index].description = value;
    }
    setForm(_form);    
    if (onFormChange && onFormChange instanceof Function) {
      onFormChange(_form);
    }
  };

  const onTextAreaChange = (formDetailId, value) => {
    const _form = [...form];
    var index = _form.findIndex(formFiled => formFiled.formDetailId === formDetailId);
    if (index >= 0) {
      _form[index].description = value;
    }
    setForm(_form);
    if (onFormChange && onFormChange instanceof Function) {
      onFormChange(_form);
    }
  };

  // #endregion

  // #region Methods

  const getStep = (userId, moduleId, stepNo) => {
    fetch(`${process.env.REACT_APP_API_URL}/lagranvida/v1/userProgram/getStep`, {
      method: 'POST',      
      body: JSON.stringify({
        userId,
        moduleId,
        stepNo
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      }
    }).then(response => response.json()).then(data => {
      if (data.responseCode === 'OK') {
        console.info('Step:', data);
        setStep(data.responseObject);        
        let form = data.responseObject.form;
        form = setupAnwers(form);
        refreshForm(form);
      } else if (data.status === 403) {
        // Session expired
        logout();
      }
    }, (error) => {
      console.error('Error getting keep growing programs', error);
      // TODO: Show error message to the user
    }); 
  };

  const setupAnwers = (form) => {
    const _updatedForm = form.map(item => ({
      ...item,
      description: item.requiresAnswer ? decodeURIComponent(item.description) : item.description
    }));
    return _updatedForm;
  };

  const refreshForm = (form) => {
    setForm(form);    
    if (onFormChange && onFormChange instanceof Function) {
      onFormChange(form);
    }
  };

  // #endregion

  // #region Templates

  const stepTextContentTemplate = (formField) => {
    return (
      <>
        <FormFieldText 
          text={formField.description}
          bold={formField.bold}
          italic={formField.italic}
          underline={formField.underline}
        />
      </>
    );
  };

  const stepTextBoxContentTemplate = (formField) => {
    return (
      <>
        <FormFieldTextBox
          id={formField.formDetailId}
          completed={completed}
          value={formField.description}
          onChange={onTextBoxChange}
        />
      </>
    );
  };

  const stepVideoContentTemplate = (formField) => {
    return (
      <>
        <FormFieldVideo
          videoURL={formField.url}
        />
      </>
    );
  };

  const stepImageContentTemplate = (formField) => {
    return (
      <>
        <FormFieldImage
          imageURL={formField.url}
        />        
      </>
    );
  };


  const stepTableContentTemplate = (formField) => {
    return (
      <>
        <FormFieldTable          
        />
      </>
    );
  };

  const stepTextAreaContentTemplate = (formField) => {
    return (
      <>
        <FormFieldTextArea
          id={formField.formDetailId}
          completed={completed}
          value={formField.description}
          onChange={onTextAreaChange}
        />
      </>
    );
  };  

  const stepSubtitleContentTemplate = (formField) => {
    return (
      <>
        <FormFieldSubtitle
          text={formField.description}
          bold={formField.bold}
          italic={formField.italic}
          underline={formField.underline}
        />
      </>
    );
  };

  const formFieldTemplate = (formField) => {
    return (
      <>
        {
          formField && (
            <>
              {formField.fieldTypeId === Program.fieldType.TEXT && stepTextContentTemplate(formField)}
              {formField.fieldTypeId === Program.fieldType.TEXT_BOX && stepTextBoxContentTemplate(formField)}              
              {formField.fieldTypeId === Program.fieldType.IMAGE && stepImageContentTemplate(formField)}
              {formField.fieldTypeId === Program.fieldType.VIDEO && stepVideoContentTemplate(formField)}
              {formField.fieldTypeId === Program.fieldType.TABLE && stepTableContentTemplate(formField)}              
              {formField.fieldTypeId === Program.fieldType.TEXT_AREA && stepTextAreaContentTemplate(formField)}              
              {formField.fieldTypeId === Program.fieldType.SUBTITLE && stepSubtitleContentTemplate(formField)}              
            </>
          )
        }
      </>
    )
  };


  const formTemplate = (form) => {
    return (
      <>
        {
          (form && form.length > 0) && (
            form.map((formField, index) => {
              return formFieldTemplate(formField, index);
            })
          )
        }
      </>
    )
  };

  const stepTemplate = (
    <>
      {
        step && (
          <>
            <Row>
              <Col>
                <h1 className='app-secondary-text app-pharma-bold-font text-uppercase text-center'>
                  {step.stepTitle}
                </h1>
              </Col>
            </Row>
            <Row>
              <Col className='p-3'>
                {formTemplate(form)}                
              </Col>
            </Row>   
          </>
        )
      }
    </>
  );

  // #endregion

  return (
    <>
      {stepTemplate}
    </>
  );
}

export default ModuleStep;
