import { useEffect } from 'react';
import ProgressText from './ProgressText/ProgressText';

function MyProgramGridItem({  
  image,
  title,
  progress,
  completed,
  onImagePressed
}) {

  // #region Events

  useEffect(() => {

  }, []);

  const onImagePressedEvent = () => {
    if (onImagePressed && onImagePressed instanceof Function) {
      onImagePressed();
    }
  };

  // #endregion

  // #region Templates

  const itemTemplate = (
    <>
      <div className='p-3'>
        <a
          href='javascript:void(0)'
          onClick={onImagePressedEvent}
        >
          <img
            src={image}
            alt={title}
            className='w-100 shadow'
            style={{
              borderRadius: '2rem',
            }}
          />
        </a>
      </div>
      <h4 className='app-neutral-black-text app-pharma-bold-font text-uppercase text-center'>
        {title}
      </h4>
      <ProgressText
        progress={progress}
        completed={completed}
      />
    </>
  );

  // #endregion

  return (
    <>
      {itemTemplate}
    </>
  );
}

export default MyProgramGridItem;
