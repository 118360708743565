import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ProgressText({
  progress,
  completed
}) {

  // #region Templates

  const inProgressTextTemplate = (
    <>
      <span className='app-secondary-text'>
        {`Avance: ${progress}%`}
      </span>
    </>
  );

  const completedTextTemplate = (
    <>
      <span className='app-quinary-text'>
        {`Avance ${progress}%`}&nbsp;
        <FontAwesomeIcon
          icon={faCheck}
        />
      </span>
    </>
  );

  const textTemplate = (
    <>
      <p className='app-montserrat-medium-font text-end'>
        {completed ? completedTextTemplate : inProgressTextTemplate}
      </p>
    </>
  );

  // #endregion

  return (
    <>
      {textTemplate}
    </>
  );
}

export default ProgressText;
