import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';

function FormFieldSubtitle({
  text,
  bold,
  underline,
  italic
}) {

  // #region Vars and Consts

  const [style, setStyle] = useState({});

  // #endregion

  // #region Events

  useEffect(() => {
    if (
      text !== null
      && bold !== null
      && underline !== null
      && italic !== null
    ) {
      setStyle({
        fontStyle: italic ? 'italic' : '',
        textDecoration: underline ? 'underline' : ''
      });
    }
  }, [
    bold,
    underline,
    italic
  ]);

  // #endregion

  // #region Templates

  const textTemplate = (
    <>
      <h3
        className={`${bold ? 'app-pharma-bold-font' : 'app-pharma-regular-font'} app-secundary-text text-uppercase text-start`}
        style={style}
      >
        {text}
      </h3>
    </>
  );

  // #endregion

  return (
    <>
      <Container>
        {textTemplate}
      </Container>
    </>
  );
}

export default FormFieldSubtitle;
