import { Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

function FormFieldTextBox({
  id,
  value,
  completed,
  onChange
}) {

  // #region Events

  const onChangeEvent = (e) => {    
    if (onChange && onChange instanceof Function) {
      onChange(id, e.target.value);
    }
  };

  // #endregion

  // #region Methods

  // #region Templates

  const textBoxTemplate = (
    <>
      {
        !completed ? (
          <Form.Control
            className='app-montserrat-medium-font app-quinary-text mb-3'
            placeholder=""
            aria-label=""
            aria-describedby=""
            value={value}
            disabled={completed}
            onChange={onChangeEvent}
          />     
        ) : (
          <p className='app-montserrat-medium-font app-quinary-text'>
            {value}
          </p>
        )
      }
  
    </>
  );

  // #endregion

  return (
    <>
      <Container>
        {textBoxTemplate}
      </Container>
    </>
  );
}

export default FormFieldTextBox;
