import { useLocation, useNavigate } from "react-router-dom";

export const useNav = () => {

  // #region Vars and Consts

  const _navigate = useNavigate();
  const location = useLocation();

  // #endregion

  // #region  Methods

  const navigateToView = (view) => {
    _navigate(view);
  };

  const navigateToSection = (view, section, params) => {
    // Setup URL params
    if (params) {
      view = setupParams(view, params);
    }    
    if (location.pathname === view) {
      // Just scroll to the beginning if its the  same URL
      document.getElementById(section).scrollIntoView();
    } else {
      // Navigate and scroll to the beginning
      _navigate(view);
      setTimeout(() => {
        document.getElementById(section).scrollIntoView();
      }, 1000);
    }
  };

  const navigate = (view, section, params) => {
    // Setup URL params
    if (params) {
      view = setupParams(view, params);
    }
    if (view && section) {      
      navigateToSection(view, section);
    } else if (view) {
      navigateToView(view);
    }  
  };

  const getNavPath = (view, params) => {
    // Setup URL params
    if (params) {
      view = setupParams(view, params);
    }
    return view;
  };

  const setupParams = (view, params) => {
    return view = view.replace(/:\w+/g, function (all) {
      return params[all] || all;
    });
  };

  // #endregion

  return { navigate, getNavPath };
  
};
