import { useEffect } from 'react';
import { Container } from 'react-bootstrap';

function PaymentSucccessDetailBox(props) {

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  const detailTemplate = (
    <>
      <Container
        className='text-end app-montserrat-medium-font app-neutral-grey-1-text fs-4'
      >
        <h3 className='app-primary-text'>
          {props.productName} 
        </h3>
        <p>
          <b>
            {`$${props.total.toFixed(2)}`}
          </b>
        </p>
        <p>
          {`No. de Orden ${props.orderNumber}`}
        </p>
        <div style={{height:'550px'}}>

        </div>
      </Container>
    </>
  );

  return (
    <>
      <section>
        {detailTemplate}
      </section>
    </>
  );
}

export default PaymentSucccessDetailBox;