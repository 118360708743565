import { useContext } from 'react';
import { Container } from 'react-bootstrap';
import { AppContext } from '../../../../../../../../context/appContext';
import * as Viewports from "../../../../../../../../constants/viewports";

function FormFieldImage({
  imageURL
}) {

  // #region Vars and Consts

  const { viewport } = useContext(AppContext);
  
  // #endregion

  // #region Methods

  const getImageWith = () => {
    let width = 100;
    switch (viewport) {
      case Viewports.sizes.EXTRA_SMALL:
      case Viewports.sizes.SMALL:
        width = 100;
        break;
      case Viewports.sizes.MEDIUM:
      case Viewports.sizes.LARGE:
        width = 75;
        break;
      case Viewports.sizes.EXTRA_LARGE:        
      case Viewports.sizes.EXTRA_EXTRA_LARGE:
        width = 50;
        break;
    }
    return width;
  };

  // #endregion

  // #region Templates

  const imageTemplate = (
    <>
      <img
        src={imageURL}
        className={`w-${getImageWith()} mt-3 mb-3 shadow`}
        style={{
          borderRadius: '1.5rem'
        }}
      />
    </>
  );

  // #endregion

  return (
    <>
      <Container className='text-center'>
        {imageTemplate}
      </Container>
    </>
  );
}

export default FormFieldImage;
