export const status = {
  PENDING: 1,
  IN_PROGRESS: 2,
  COMPLETED: 3
};

export const fieldType = {
  TEXT: 1,
  TEXT_BOX: 2,
  VIDEO: 3,
  IMAGE: 4,  
  TABLE: 5,
  TEXT_AREA: 6,
  SUBTITLE: 7
};