import { useEffect, useState } from 'react';
import HeaderBanner from '../../_common/headers/HeaderBanner/HeaderBanner';
import KeepGrowingCarousel from '../../_common/programs/KeepGrowingCarousel/KeepGrowingCarousel';
import MyProgramBox from './MyProgramBox/MyProgramBox';
import * as Paths from "../../../../../constants/paths";
import { useAuth } from '../../../../../hooks/useAuth';
import { useNav } from '../../../../../hooks/useNav';
import { useParams } from 'react-router-dom';

function MyProgramView() {

  // #region Vars and Consts

  const nav = useNav();
  const { id } = useParams();
  const { user, logout } = useAuth();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [program, setProgram] = useState(null);

  // #endregion

  // #region Events

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    getProgram(user.userId, id);
  }, []);

  // #endregion

  // #region Methods

  const getProgram = (
    userId,
    programId
  ) => {
    fetch(`${process.env.REACT_APP_API_URL}/lagranvida/v1/userProgram/getProgram?userId=${userId}&programId=${programId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      }
    }).then(response => response.json()).then(data => {
      if (data.responseCode === 'OK') {
        console.info('Program:', data);
        setProgram(data.responseObject);
        setupBreadcrumbs(data.responseObject);
      } else if (data.status === 403) {
        // Session expired
        logout();
      }
    }, (error) => {
      console.error('Error getting program', error);
      // TODO: Show error message to the user
    });
  };

  const setupBreadcrumbs = (program) => {
    const breadcrumbs = [{
      name: 'Mis Programas',
      navPath: `#${Paths.views.user.MY_PROGRAMS}`
    }, {
      name: program.title
    }];
    setBreadcrumbs(breadcrumbs);
  };

  // #endregion

  // #region Templates

  const headerTemplate = (
    <>
      {
        program && (
          <HeaderBanner
            title={program.title}
            breadcrumbs={breadcrumbs}    
          />
        )
      }
    </>
  );

  // #endregion

  return (
    <>
      <article className='app-has-navbar'>
        <section>
          {headerTemplate}
        </section>
        <section className='app-quaternary-bg'>
          <MyProgramBox program={program} />  
        </section>
        <section>
          <KeepGrowingCarousel />
        </section>
      </article>
    </>
  );
}

export default MyProgramView;
