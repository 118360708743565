import { Carousel } from 'primereact/carousel';
import { Container } from 'react-bootstrap';
import specialEvent1Image from '../../../../../../assets/images/special-events/la-gran-vida-special-event-1.png';
import specialEvent2Image from '../../../../../../assets/images/special-events/la-gran-vida-special-event-2.png';
import specialEvent3Image from '../../../../../../assets/images/special-events/la-gran-vida-special-event-3.png';
import * as Viewports from "../../../../../../constants/viewports";

function SpecialEventsBox() {

  // #region Vars and Consts

  const blogs = [
    {
      id: 1,      
      image: specialEvent1Image
    },
    {
      id: 2,      
      image: specialEvent2Image
    },
    {
      id: 3,      
      image: specialEvent3Image
    }
  ];
  
  const responsiveOptions = [
    {
      breakpoint: `${Viewports.breakpoints.EXTRA_LARGE}px`,
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: `${Viewports.breakpoints.LARGE}px`,
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: `${Viewports.breakpoints.MEDIUM}px`,
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: `${Viewports.breakpoints.SMALL}px`,
      numVisible: 1,
      numScroll: 1
    }
  ];

  // #endregion

  // #region Templates

  const blogTemplate = (program) => {
    return (
      <div className="text-center m-4">
        <img
          src={program.image}
          alt={program.title}
          className="w-100 shadow"
          style={{
            borderRadius: '1.5rem'
          }}
        />  
      </div>
    );
  };

  const carouselTemplate = (
    <>
      <Container>        
        <Carousel
          value={blogs}
          numVisible={4}
          numScroll={1}
          responsiveOptions={responsiveOptions}
          itemTemplate={blogTemplate}
          circular
          autoplayInterval={3000}          
        />      
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article className='app-neutral-white-bg'>
        <section>
          <h1 className='app-neutral-black-text app-pharma-bold-font text-uppercase text-center pt-5'>
            Eventos Especiales
          </h1>
          {carouselTemplate}
        </section>
      </article>
    </>
  );
}

export default SpecialEventsBox;