import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useAuth } from "../../../../../hooks/useAuth";
import { useNav } from "../../../../../hooks/useNav";
import SocialNetworksBox from "../../../../_common/social/SocialNetworksBox/SocialNetworksBox";
import BrandLogo from "../../brand/BrandLogo/BrandLogo";
import * as Paths from "../../../../../constants/paths";

const Footer = () => {
  const nav = useNav();
  const { hasPermission, user, logout } = useAuth();

  // #region Events

  const onTermsOfUsePress = () => {
    nav.navigate(Paths.views.TERMS_AND_CONDITIONS);
  };

  const onPrivacyStatementPress = () => {
    nav.navigate(Paths.views.PRIVACY_POLICY);
  };

  // #endregion

  // #region Templates

  const termsOfUseNavTemplate = (
    <>
      <Button
        variant="primary"
        className="app-quaternary-button app-montserrat-medium-font app-neutral-grey-1-text"
        onClick={onTermsOfUsePress}
      >
        Términos y Condiciones
      </Button>
    </>
  );

  const privacyStatementNavTemplate = (
    <>      
      <Button
        variant="primary"
        className="app-quaternary-button app-montserrat-medium-font app-neutral-grey-1-text"
        onClick={onPrivacyStatementPress}
      >
        Aviso de Privacidad
      </Button>
    </>
  );

  const rightsNavTemplate = (
    <>
      <p
        className="app-montserrat-regular-font app-neutral-grey-1-text p-2"
      >
        © 2024 La Gran Vida All Rights Reserved. Secured by SSL.
      </p>
    </>
  );

  // #endregion

  return (
    <>
      <Row className='app-quaternary-bg align-middle'>
        <Col
          lg={2}
          className="p-1"
        >
          <BrandLogo />
        </Col>
        <Col 
          lg={4} 
          className="py-4"
        >
          {rightsNavTemplate} 
        </Col>
        <Col 
          lg={2} 
          className="py-4"
        >
          {termsOfUseNavTemplate}
        </Col>
        <Col 
          lg={2} 
          className="py-4"
        >
          {privacyStatementNavTemplate}
        </Col>
        <Col 
          lg={2} 
          className="py-2"
        >
          <SocialNetworksBox />
        </Col>
      </Row>
    </>
  )
}

export default Footer;