import { Carousel } from 'primereact/carousel';
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import * as Viewports from "../../../../../../constants/viewports";
import { useAuth } from '../../../../../../hooks/useAuth';
import { useNav } from '../../../../../../hooks/useNav';

function KeepGrowingCarousel() {

  // #region Vars and Consts

  const nav = useNav();
  const { user, logout } = useAuth();
  const [programs, setPrograms] = useState([]);
  const carouselResponsiveOptions = [
    {
      breakpoint: `${Viewports.breakpoints.EXTRA_LARGE}px`,
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: `${Viewports.breakpoints.LARGE}px`,
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: `${Viewports.breakpoints.MEDIUM}px`,
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: `${Viewports.breakpoints.SMALL}px`,
      numVisible: 1,
      numScroll: 1
    }
  ];

  // #endregion

  // #region Events

  useEffect(() => {    
    getPrograms();
  }, []);

  const onProgramPressed = (program) => {
    goToProgram(program);    
  };

  // #endregion

  // #region Methods

  const getPrograms = () => {    
    fetch(`${process.env.REACT_APP_API_URL}/lagranvida/v1/program/getPrograms?userId=${user.userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      }      
    }).then(response => response.json()).then(data => {      
      if (data.responseCode === 'OK') {
        console.info('Keep growing Programs:', data);
        setPrograms(data.responseObject);
      } else if (data.status === 403) {
        // Session expired
        logout();        
      }
    }, (error) => {
      console.error('Error getting keep growing programs', error);
      // TODO: Show error message to the user
    });    
  };

  const goToProgram = (program) => {
    if (program && program.viewPath !== '') {
      nav.navigate(program.viewPath);
    }  
  };

  // #endregion

  // #region Templates

  const overlayTemplate = (
    <>
      <div
        className='position-absolute top-0 start-0 app-quinary-bg'
        style={{
          borderRadius: '1.5rem',
          width: '100%',
          height: '100%',
          opacity: '0.7'
        }}
      >
      </div>
    </>
  );

  const programTemplate = (program) => {
    return (
      <>
        {
          program && (
            <div className="text-center m-4 position-relative">
              <img
                src={program.imageUrl}
                alt={program.name}
                className="w-100"
                style={{
                  borderRadius: '1.5rem'
                }}
              />
              {!program.active && overlayTemplate}
              <Button
                className="text-uppercase app-pharma-bold-font app-primary-button position-absolute px-3 top-100 start-50 translate-middle"
                variant='primary'
                size={Viewports.sizes.LARGE}
                style={{
                  minWidth: '90%'
                }}
                onClick={() => { onProgramPressed(program) }}
              >
                {program.name}
              </Button>
            </div>
          )
        }
      </>
    );
  };

  const carouselTemplate = (
    <>
      <Carousel
        value={programs}
        responsiveOptions={carouselResponsiveOptions}
        itemTemplate={programTemplate}  
        numVisible={4}
        numScroll={1} 
        autoplayInterval={3000}
        circular     
      />            
    </>
  );

  // #endregion

  return (
    <>
      <article className='app-secondary-bg'>        
        <Container>        
          <h1 className='app-primary-text app-pharma-bold-font text-uppercase text-start ps-4 pt-5'>
            Sigue Creciendo
          </h1>
          {carouselTemplate}
        </Container>
      </article>
    </>
  );
}

export default KeepGrowingCarousel;
