import { Container } from 'react-bootstrap';
import relationshipToolsImage from '../../../../../../assets/images/ads/la-gran-vida-ad-herramientas-de-relacionamiento.png';

function Ad1Box() {

  // #region Templates

  const linkAdImageTemplate = (
    <>
      <a
        href='https://a.co/d/0hq9Yjih'
        target='_black'
      >
        <img
          src={relationshipToolsImage}
          alt='Libro Herramientas de relacionamiento de Oscar Javier Navarro Mojica'
          className="w-100 shadow"
          style={{
            borderRadius: '1.5rem'
          }}
        />
      </a>
    </>
  );

  const inlineAdTemplate = (
    <>
      <Container
        fluid
        className='w-100 px-5'      
      >
        {linkAdImageTemplate}
      </Container>
    </>
  );

  const floatingAdTemplate = (
    <>
      <Container
        fluid
        className='position-absolute w-100 px-5'
        style={{
          left: '0',
          bottom: '75px'
        }}
      >
        {linkAdImageTemplate}
      </Container>
    </>
  );


  const adTemplate = (
    <>
      <div className='d-none d-lg-block'>
        {floatingAdTemplate}      
      </div>
      <div className='d-block d-lg-none pt-5'>
        {inlineAdTemplate}
      </div>      
    </>
  );

  // #endregion

  return (
    <>
      <article>
        <section>          
          {adTemplate}
        </section>
      </article>
    </>
  );
}

export default Ad1Box;