import { Container } from "react-bootstrap";
import Breadcrumb from "../../../../_common/Breadcrumb/Breadcrumb";

function HeaderBanner({
  title,
  subtitle,
  breadcrumbs
}) {

  const titleTemplate = (
    <>
      <Container
        fluid
        className="pt-4 pb-4"        
      >
        <div className="ps-4 pe-4">
          <Breadcrumb
            breadcrumbs={breadcrumbs}
          />
        </div>        
        <h2         
          className='app-primary-text ps-4 pe-4 app-suomi-hand-script-font'
          style={{
            fontSize: '3rem'
          }}
        >
          {title}
        </h2>
        <h4 className='app-quaternary-text ps-4 pe-4 app-pharma-bold-font text-uppercase'>
          {subtitle}
        </h4>
      </Container>
    </>
  );

  return (
    <>
      <section className="app-danger-bg">
        {titleTemplate}
      </section>
    </>
  );
}

export default HeaderBanner;
