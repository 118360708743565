import { Navigate, useOutlet } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import Footer from '../_common/footers/Footer/Footer';
import Navbar from '../_common/navbars/Navbar/Navbar';

function OpenLayout(props) {

  // #region Consts   
  
  const { user } = useAuth();
  const outlet = useOutlet();

  // #endregion

  return (
    <>
      <nav>
        <Navbar />
      </nav>
      <main>
        {outlet}
      </main>            
      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default OpenLayout;
