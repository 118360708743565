import React from "react";
import logo from '../../../../../assets/images/logo/la-gran-vida-logo-animated.gif';
import { useAuth } from "../../../../../hooks/useAuth";
import { useNav } from "../../../../../hooks/useNav";
import * as Paths from "../../../../../constants/paths";

const BrandLogo = () => {
  const nav = useNav();
  const { hasPermission, user, logout } = useAuth();

  // #region Events

  const onLogoPress = () => {
    nav.navigate(Paths.views.HOME);
  };

  // #endregion

  // #region Templates

  const brandNavTemplate = (
    <>
      <img
        src={logo}
        role="button"
        height="80"
        className="d-inline-block align-top"
        alt="La Grand Vida logo"
        onClick={onLogoPress}
      />
    </>
  );

  // #endregion

  return (
    <>
      {brandNavTemplate}
    </>
  )
}

export default BrandLogo;