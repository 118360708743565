import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import PaymentSucccessDetailBox from './PaymentSucccessDetailBox/PaymentSucccessDetailBox';

function PaymentSucccessView() {

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  const paymentSuccessTemplate = (
    <>
      <Container className='pt-4 pb-4 text-end'>
        <h1 className='app-pharma-bold-font app-neutral-white-text text-uppercase'>
          ¡Gracias por tu orden!
        </h1>
        <PaymentSucccessDetailBox
          productName={'40 días para una gran vida'}
          total={1200}
          orderNumber={12345}
        />
      </Container>
    </>
  );

  return (
    <>
      <article className='app-secondary-bg app-has-navbar h-100'>
        <section>          
          {paymentSuccessTemplate}
        </section>        
      </article>
    </>
  );
}

export default PaymentSucccessView;