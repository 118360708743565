import { createContext, useContext, useMemo } from "react";
import { useLocalStorage } from "./useLocalStorage";
import * as Paths from "../constants/paths";
import { useNav } from "./useNav";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const nav = useNav();
  const [user, setUser] = useLocalStorage("user", null);    

  const login = async (data, navTo) => {  
    setUser(data);      
    nav.navigate(navTo);        
  };

  const logout = () => {
    setUser(null);
    nav.navigate(Paths.views.HOME);
  };

  const hasPermission = (permission) => {
    let hasPermission = false;
    if (user && user.pages) {
      hasPermission = (user.pages.find(item => item === permission))    
    }     
    return hasPermission;
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      hasPermission
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
