import { faSpotify, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Container, Row } from 'react-bootstrap';
import enhanceYourSkillsAdImage from '../../../../../../assets/images/ads/la-gran-vida-ad-enhance-your-skills.png';
import podcastAdImage from '../../../../../../assets/images/ads/la-gran-vida-ad-podcast.jpeg';
import * as Viewports from "../../../../../../constants/viewports";

function Ad2Box() {

  // #region Templates

  const linkAdImageTemplate = (
    <>
      <a
        href='https://a.co/d/0hq9Yjih'
        target='_black'
      >
        <img
          src={enhanceYourSkillsAdImage}
          alt='Enhance your skills'
          className="w-100"
          style={{
            borderRadius: '1.5rem'
          }}
        />
      </a>
    </>
  );

  const imageWithButtonsTemplate = (
    <>
      <Row>
        <Col>
          <img
            src={podcastAdImage}
            alt='Podcast'
            className="w-100"
            style={{
              borderRadius: '1.5rem'
            }}
          />
        </Col>
      </Row>
      <Row className='pt-3'>
        <Col>
          <Button
            className="text-uppercase app-accent-button app-pharma-bold-font w-100"
            variant='primary'
            href="https://www.youtube.com/@la.granvidamx"
            target='_blank'
            size={Viewports.sizes.LARGE}
          >
            YouTube&nbsp;
            <FontAwesomeIcon
              icon={faYoutube}
            />
          </Button>  
        </Col>
        <Col className='text-end'>
          <Button
            className="text-uppercase app-accent-button app-pharma-bold-font w-100"
            variant='primary'
            href="https://open.spotify.com/episode/1Pip10kU3ERE4ETgxRYWSV?si=3dFkyFB1T92BkBHHbYjHPw&sfnsn=scwspwa"
            target='_blank'
            size={Viewports.sizes.LARGE}
          >
            Spotify&nbsp;
            <FontAwesomeIcon
              icon={faSpotify}
            />
          </Button>  
        </Col>
      </Row>
    </>
  );

  const adTemplate = (
    <>
      <Container
        fluid
        className='w-100 px-5 py-5 mb-4'
      >
        {imageWithButtonsTemplate}
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article>
        <section>
          {adTemplate}
        </section>
      </article>
    </>
  );
}

export default Ad2Box;