import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import * as Paths from "../../../../../../constants/paths";
import * as Program from "../../../../../../constants/program";
import * as Viewports from "../../../../../../constants/viewports";
import { AppContext } from '../../../../../../context/appContext';
import { useAuth } from '../../../../../../hooks/useAuth';
import { useNav } from '../../../../../../hooks/useNav';
import FinishModuleModal from './FinishModuleModal/FinishModuleModal';
import ModuleStep from './ModuleStep/ModuleStep';

function ModuleStepper({
  program
}) {
  
  // #region Vars and Consts

  const nav = useNav();
  const stepperRef = useRef(null);
  const { viewport } = useContext(AppContext);
  const { user, logout } = useAuth();
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeStepForm, setActiveStepForm] = useState([]);
  const [finisherStep, setFinisherStep] = useState(null);
  const [showFinishModuleModal, setShowFinishModuleModal] = useState(false);

  // #endregion

  // #region Events

  useEffect(() => {
    if (program) {
      setActiveIndex(program.step.currentStep - 1);            
    }    
  }, [program]);

  const onBackPress = (step) => {
    stepBackward();
  };

  const onNextPress = (step) => {
    stepForward(step);
  };

  const onFinishPress = (step) => {
    setFinisherStep(step);
    setShowFinishModuleModal(true);
  };

  const onModulePress = (step) => {
    stepperRef.current.nextCallback();
    goToProgram(program.programId);
  };

  const onStepChange = (e) => {
    setActiveIndex(e.index);
  };

  const onActiveStepFormChange = (form) => {
    setActiveStepForm(form);
  };

  const onHideFinishConfirmation = () => {
    setShowFinishModuleModal(false);
  };

  const onContinueFinishConfirmation = () => {
    setShowFinishModuleModal(false);
    finishModule(finisherStep);
  };

  // #endregion

  // #region Methods

  const stepBackward = (step) => {
    stepperRef.current.prevCallback();
  };

  const stepForward = (step) => {
    if (!program.completed) {
      save(
        user.userId,
        program.moduleId,
        step,
        activeStepForm,
        () => { stepperRef.current.nextCallback(); }
      );
    } else {
      stepperRef.current.nextCallback();
    }
  };

  const finishModule = (step) => {
    if (!program.completed) {
      save(
        user.userId,
        program.moduleId,
        step,
        activeStepForm,
        () => {
          stepperRef.current.nextCallback();
          goToProgram(program.programId);
        }
      );    
    } else {
      stepperRef.current.nextCallback();
    }
  };

  const getAnswers = (form) => {
    const formFieldsFiltered = form.filter((formField) => {
      return (formField.requiresAnswer)
    });
    return formFieldsFiltered.map(({ formDetailId, description }) => ({ formDetailId, answer: encodeURIComponent(description) }));
  };

  const save = (
    userId,
    moduleId,
    step,
    form,
    onSaved
  ) => {
    const answers = getAnswers(form);
    fetch(`${process.env.REACT_APP_API_URL}/lagranvida/v1/userProgram/saveStep?`, {
      method: 'POST',
      body: JSON.stringify({
        userId,
        moduleId,
        step,
        answers
      }),      
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      }
    }).then(response => response.json()).then(data => {
      if (data.responseCode === 'OK') {
        console.info('Step saved:', data);        
        if (onSaved && onSaved instanceof Function) {
          onSaved();
        }   
      } else if (data.status === 403) {
        // Session expired
        logout();
      }
    }, (error) => {
      console.error('Error getting keep growing programs', error);
      // TODO: Show error message to the user
    });    
  };

  const goToProgram = (programId) => {
    nav.navigate(
      Paths.views.user.MY_PROGRAM,
      null,
      {
        ':id': programId
      }
    );  
  };

  // #endregion

  // #region Templates

  const backbuttonTemplate = (step) => {
    return (
      <>
        <Button
          className="text-uppercase app-pharma-bold-font app-accent-button"
          variant='primary'
          onClick={() => { onBackPress(step) }}
          size={Viewports.sizes.LARGE}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
          />&nbsp;
          Regresar
        </Button>
      </>
    );
  };

  const nextBuittonTemplate = (step) => {
    return (
      <>
        <Button
          className="text-uppercase app-pharma-bold-font app-accent-button"
          variant='primary'
          onClick={() => { onNextPress(step) }}
          size={Viewports.sizes.LARGE}
        >
          Siguiente&nbsp;
          <FontAwesomeIcon
            icon={faChevronRight}
          />
        </Button>
      </>
    );
  };

  const finishBuittonTemplate = (step) => {
    return (
      <>
        <Button
          className="text-uppercase app-pharma-bold-font app-accent-button"
          variant='primary'
          onClick={() => { onFinishPress(step) }}
          size={Viewports.sizes.LARGE}
        >
          Finalizar&nbsp;
          <FontAwesomeIcon
            icon={faChevronRight}
          />
        </Button>
      </>
    );
  };

  const modulesBuittonTemplate = (step) => {
    return (
      <>
        <Button
          className="text-uppercase app-pharma-bold-font app-accent-button"
          variant='primary'
          onClick={onModulePress}
          size={Viewports.sizes.LARGE}
        >
          Módulos&nbsp;
          <FontAwesomeIcon
            icon={faChevronRight}
          />
        </Button>
      </>
    );
  };

  const stepTemplate = (step, index) => {
    return (
      <StepperPanel
        key={index}
      >
        <ModuleStep
          index={index}
          load={(step - 1) === activeIndex}
          moduleId={program.moduleId}
          completed={program.completed}
          stepNo={step}
          onFormChange={onActiveStepFormChange}
        />
        <Row>
          <Col className='text-left' xs={6}>
            {(index > 0) && backbuttonTemplate(step)}
          </Col>
          <Col className='text-end' xs={6}>
            {(index < program.steps.length - 1) && nextBuittonTemplate(step)}
            {(index === program.steps.length - 1 && !program.completed) && finishBuittonTemplate(step)}
            {(index === program.steps.length - 1 && program.completed) && modulesBuittonTemplate(step)}
          </Col>
        </Row>
      </StepperPanel>
    )
  };

  const stepsTemplate = (
    <>
      {
        (program && program.step) && (
          <Stepper
            className=""
            ref={stepperRef}
            style={{ flexBasis: '50rem' }}
            activeStep={program.step.currentStep - 1}
            onChangeStep={onStepChange}
            linear            
          >
            {
              (program.steps && program.steps.length > 0) && (
                program.steps.map((step, index) => {
                  return stepTemplate(step, index);
                })
              )
            }
          </Stepper>
        )
      }
    </>
  );

  const finihsModuleConfirmationModal = (
    <>
      {
        showFinishModuleModal && (
          <FinishModuleModal
            show={showFinishModuleModal}
            onHide={onHideFinishConfirmation}
            onContinue={onContinueFinishConfirmation}
          />
        )
      }      
    </>
  );

  // #endregion

  return (
    <>
      <Container
        className='app-tertiary-bg p-3'
        style={{
          borderRadius: '2rem'
        }}
      >
        {stepsTemplate}
      </Container>
      {finihsModuleConfirmationModal}
    </>
  );
}

export default ModuleStepper;
