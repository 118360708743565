import React, { createContext, useState, useEffect } from 'react';
import * as Viewports from "../constants/viewports";

export const AppContext = createContext();

const AppContextProvider = (props) => {
  const [viewport, setViewport] = useState(null);

  const getViewport = () => {
    const width = window.innerWidth;
    let viewport;
    if (width >= Viewports.breakpoints.EXTRA_EXTRA_LARGE) {
      viewport = Viewports.sizes.EXTRA_EXTRA_LARGE
    } else if (width >= Viewports.breakpoints.EXTRA_LARGE) {
      viewport = Viewports.sizes.EXTRA_LARGE;
    } else if (width >= Viewports.breakpoints.LARGE) {
      viewport = Viewports.sizes.LARGE;
    } else if (width >= Viewports.breakpoints.MEDIUM) {
      viewport = Viewports.sizes.MEDIUM;
    } else if (width >= Viewports.breakpoints.SMALL) {
      viewport = Viewports.sizes.SMALL;
    } else {
      viewport = Viewports.sizes.EXTRA_SMALL;
    }
    setViewport(viewport);    
  };

  useEffect(() => {
    getViewport();
    window.addEventListener('resize', getViewport);
    return () => {
      window.removeEventListener('resize', getViewport);
    }
  }, []);

  return (
    <>
      <AppContext.Provider value={{
        viewport        
      }}
      >
        {props.children}
      </AppContext.Provider>      
    </>
  )
}

export default AppContextProvider;
