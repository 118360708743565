import { PrimeReactProvider } from 'primereact/api';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, Route, Routes } from "react-router-dom";
import NoSessionLayout from './components/layouts/NoSessionLayout/NoSessionLayout';
import SessionAwareLayout from './components/layouts/SessionAwareLayout/SessionAwareLayout';
import SessionLayout from './components/layouts/SessionLayout/SessionLayout';
import AboutUsView from './components/views/sessionAware/about/AboutUsView/AboutUsView';
import AbundanceProgramView from './components/views/sessionAware/programs/AbundanceProgramView/AbundanceProgramView';
import FAQsView from './components/views/sessionAware/about/FAQsView/FAQsView';
import FourtyDaysProgramView from './components/views/sessionAware/programs/FourtyDaysProgramView/FourtyDaysProgramView';
import HomeView from './components/views/sessionAware/home/HomeView/HomeView';
import JoyProgramView from './components/views/sessionAware/programs/JoyProgramView/JoyProgramView';
import LoveProgramView from './components/views/sessionAware/programs/LoveProgramView/LoveProgramView';
import MyProgramModuleView from './components/views/session/myPrograms/MyProgramModuleView/MyProgramModuleView';
import MyProgramsView from './components/views/session/myPrograms/MyProgramsView/MyProgramsView';
import MyProgramView from './components/views/session/myPrograms/MyProgramView/MyProgramView';
import PaymentSucccessView from './components/views/session/payment/PaymentSucccessView/PaymentSucccessView';
import PrivacyPolicyView from './components/views/sessionAware/about/PrivacyPolicyView/PrivacyPolicyView';
import RecommendedPodcastsBlogView from './components/views/sessionAware/blogs/RecommendedPodcastsBlogView/RecommendedPodcastsBlogView';
import SignUpView from './components/views/noSession/signUp/SignUpView/SignUpView';
import TermsAndConditionsView from './components/views/sessionAware/about/TermsAndConditionsView/TermsAndConditionsView';
import WellnessProgramView from './components/views/sessionAware/programs/WellnessProgramView/WellnessProgramView';
import WhatCanISeeInTheMovieBlogView from './components/views/sessionAware/blogs/WhatCanISeeInTheMovieBlogView/WhatCanISeeInTheMovieBlogView';
import WhatsInTheBookBlogView from './components/views/sessionAware/blogs/WhatsInTheBookBlogView/WhatsInTheBookBlogView';
import * as Paths from "./constants/paths";
import AppContextProvider from './context/appContext';
import { AuthProvider } from "./hooks/useAuth";
import reportWebVitals from './reportWebVitals';
import './styles/styles.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppContextProvider>
      <PrimeReactProvider>
        <HashRouter>      
          <AuthProvider>
            <Routes> 
              <Route element={<SessionAwareLayout />}>
                <Route path={Paths.views.HOME} element={<HomeView />} />
                <Route path={Paths.views.blogs.WHATS_IN_THE_BOOK_OF} element={<WhatsInTheBookBlogView />} />
                <Route path={Paths.views.blogs.RECOMMENDED_PODCASTS} element={<RecommendedPodcastsBlogView />} />
                <Route path={Paths.views.blogs.WHAT_CAN_I_SEE_IN_THE_MOVIE_OF} element={<WhatCanISeeInTheMovieBlogView />} />                
                <Route path={Paths.views.programs.FOURTY_DAYS_FOR_AN_AMAZING_LIFE} element={<FourtyDaysProgramView />} />
                <Route path={Paths.views.programs.ABUNDANCE} element={<AbundanceProgramView />} />
                <Route path={Paths.views.programs.LOVE} element={<LoveProgramView />} />
                <Route path={Paths.views.programs.WELLNESS} element={<WellnessProgramView />} />
                <Route path={Paths.views.programs.JOY} element={<JoyProgramView />} />
                <Route path={Paths.views.aboutUs.ABOUT_US} element={<AboutUsView />} />
                <Route path={Paths.views.aboutUs.FAQS} element={<FAQsView />} />
                <Route path={Paths.views.PRIVACY_POLICY} element={<PrivacyPolicyView />} />                
                <Route path={Paths.views.TERMS_AND_CONDITIONS} element={<TermsAndConditionsView />} />                
                <Route path="*" element={<HomeView to="/" replace />} />   
              </Route>
              <Route element={<NoSessionLayout />}>
                <Route path={Paths.views.SIGN_UP} element={<SignUpView />} />
              </Route>  
              <Route element={<SessionLayout />}>
                <Route path={Paths.views.user.MY_PROGRAMS} element={<MyProgramsView />} />
                <Route path={Paths.views.user.MY_PROGRAM} element={<MyProgramView />} />
                <Route path={Paths.views.user.MY_PROGRAM_MODULE} element={<MyProgramModuleView />} />
                <Route path={Paths.views.user.PAYMENT_SUCCESS} element={<PaymentSucccessView />} />                             
              </Route>       
            </Routes>   
          </AuthProvider>  
        </HashRouter>
      </PrimeReactProvider>
    </AppContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
