import { Button, Col, Container, Row } from 'react-bootstrap';
import headerImage from '../../../../../../assets/images/blogs/la-gran-vida-blog-que-puedo-ver-en-la-pelicula-header-image.png';
import underlineWhiteIamge from '../../../../../../assets/images/underlines/underline-white.png';

function HeroBannerBox() {

  // #region Templates
  
  const heroBannerTemplate = (
    <>
      <Container
        fluid
        style={{
          background: `url(${headerImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <Row>
          <Col className='position-relative'>
            <h1
              className='app-primary-text app-pharma-bold-font text-uppercase text-center'
              style={{
                fontSize: '3rem',
                marginTop: '10%',
                marginBottom: '10%'
              }}
            >
              ¿Qué puedo ver en la película de...?
            </h1>
            <img
              className='w-25 position-absolute'
              src={underlineWhiteIamge}
              style={{
                left: '45%',
                bottom: '30%'
              }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article>
        <section>
          {heroBannerTemplate}
        </section>
      </article>
    </>
  );
}

export default HeroBannerBox;