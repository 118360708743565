import { Container } from 'react-bootstrap';

function FormFieldTable() {

  // #region Templates

  const tableTemplate = (
    <>
      <h1>TABLE</h1>
    </>
  );

  // #endregion

  return (
    <>
      <Container>
        {tableTemplate}
      </Container>
    </>
  );
}

export default FormFieldTable;
