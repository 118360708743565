import { Carousel } from 'primereact/carousel';
import { Container } from 'react-bootstrap';
import greatMindBruceImage from '../../../../../../assets/images/great-minds/la-gran-vida-great-mind-bruce-lipton.jpg';
import greatMindJorgeImage from '../../../../../../assets/images/great-minds/la-gran-vida-great-mind-jorge-bucay.jpg';
import greatMindSadhguruImage from '../../../../../../assets/images/great-minds/la-gran-vida-great-mind-sadhguru.jpg';
import * as Viewports from "../../../../../../constants/viewports";

function GreatMindsBox() {

  // #region Vars and Consts

  const greatMinds = [
    {
      id: 1,
      image: greatMindBruceImage,
      title: 'La vibración es siempre verdadera, el lenguaje puede estar equivocado. Bruce Lipton.'
    },
    {
      id: 2,
      image: greatMindSadhguruImage,
      title: 'La experiencia de la vida debería madurarle. Es tu elección transformar un recuerdo en herida o sabiduría. Sadhgurú'
    },
    {
      id: 3,
      image: greatMindJorgeImage,
      title: 'No somos responsables de nuestras emociones, pero si de lo que hacemos con ellas. Jorge Bucay.'
    },
  ];

  const responsiveOptions = [
    {
      breakpoint: `${Viewports.breakpoints.EXTRA_LARGE}px`,
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: `${Viewports.breakpoints.LARGE}px`,
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: `${Viewports.breakpoints.MEDIUM}px`,
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: `${Viewports.breakpoints.SMALL}px`,
      numVisible: 1,
      numScroll: 1
    }
  ];

  // #endregion

  // #region Templates

  const greatMindsTemplate = (program) => {
    return (
      <div className="text-center m-4">
        <img
          src={program.image}
          alt={program.title}
          className="w-100"
          style={{
            borderRadius: '1.5rem'
          }}
        />
      </div>
    );
  };

  const carouselTemplate = (
    <>
      <Container fluid>
        <Carousel
          value={greatMinds}
          numVisible={3}
          numScroll={1}
          responsiveOptions={responsiveOptions}
          itemTemplate={greatMindsTemplate}
          circular
          autoplayInterval={3000}     
        />
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article>
        <section>
          <h1
            className='app-neutral-black-text app-suomi-hand-script-font text-center pt-5'
            style={{
              fontSize: '4rem'
            }}
          >
            Aprendemos de los grandes
          </h1>
          {carouselTemplate}
        </section>
      </article>
    </>
  );
}

export default GreatMindsBox;