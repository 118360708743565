import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import * as Viewports from "../../../../../../../../constants/viewports";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

function ModuleLink({
  disable,
  completed,
  description,
  onLinkPress
}) {

  // #region Events

  const onLinkPressEvent = () => {
    if (onLinkPress && onLinkPress instanceof Function) {
      onLinkPress();
    }     
  };

  // #endregion

  // #region Templates

  const tooltipTemplate = (props) => (
    <Tooltip id="moduleTemplate" {...props}>
      Disponible hasta terminar los módulos previos.
    </Tooltip>
  );

  const availableLinkTemplate = (
    <>
      <Button
        className="app-montserrat-medium-font app-secondary-text text-start"
        variant='link'
        onClick={onLinkPressEvent}
        size={Viewports.sizes.LARGE}
        style={{
          textDecoration: 'none'
        }}
      >
        {description}&nbsp;
        <FontAwesomeIcon
          icon={faChevronRight}
        />
      </Button>
      <span className='app-quinary-text'>
        {
          completed && (
            <FontAwesomeIcon
              icon={faCheck}
            />
          )
        }
      </span>
    </>
  );

  const disableLinkTemplate = (
    <>
      <OverlayTrigger
        placement="top-end"
        delay={{ show: 250, hide: 400 }}
        overlay={tooltipTemplate}
      >
        <Button
          className="app-montserrat-medium-font app-neutral-grey-1-text text-start"
          variant='link'
          size={Viewports.sizes.LARGE}
          style={{
            textDecoration: 'none'
          }}
        >
          {description}&nbsp;
          <FontAwesomeIcon
            icon={faChevronRight}
          />
        </Button>
      </OverlayTrigger>
    </>
  ); 

  const linkTemplate = (
    <>
      {!disable ? availableLinkTemplate : disableLinkTemplate}
    </>
  );

  // #endregion

  return (
    <>
      {linkTemplate}
    </>
  );
}

export default ModuleLink;
