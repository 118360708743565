import { Container } from 'react-bootstrap';

function FormFieldVideo({
  videoURL
}) {

  // #region Templates

  const videoTemplate = (
    <>
      <iframe
        src={videoURL}
        width="100%"
        height="550"
        frameborder="0"
        allow="encrypted-media"
        allowfullscreen=""
      />
    </>
  );

  // #endregion

  return (
    <>
      <Container>
        {videoTemplate}
      </Container>
    </>
  );
}

export default FormFieldVideo;
