import React, { useState } from 'react';
import { Alert, Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import * as yup from 'yup';
import primaryAbstractBackgroundImage from '../../../../../assets/images/backgrounds/la-gran-vida-primary-abstract-bg.png';
import Logo from '../../../../../assets/images/logo/la-gran-vida-logo.png';
import * as Paths from "../../../../../constants/paths";
import { useAuth } from '../../../../../hooks/useAuth';
import { useNav } from '../../../../../hooks/useNav';
import './SignUpView.scss';


function SignUpView() {

  // #region vars and consts

  const { login } = useAuth();
  const [alertWarningIsVisible, setAlertWarningIsVisible] = useState(false);
  const nav = useNav();
  const [errors, setErrors] = useState({});
  const [errorMessages, setErrorMessages] = useState([]);
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [agree, setAgree] = useState(false);
  const [phoneCountry, setPhoneCountry] = useState(null);
  const [phoneCountryCallingCode, setPhoneCountryCallingCode] = useState(null);
  const validationSchema = yup.object().shape({
    email: yup.string().required('El Correo electrónico es requerido.'),
    name: yup.string().required('El Nombre es requerido.'),
    lastName: yup.string().required('El Apellido es requerido.'),
    agree: yup
      .bool()
      .oneOf([true], "Es necesario estar deacuerdo con los Términos y Condiciones.")
      .required("Es necesario estar deacuerdo con los Términos y Condiciones.")
    // phone: yup.string().required('El Número telefónico es requerido.')
  });

  // #endregion

  // #region Events

  const onEmailChange = (e) => {    
    setEmail(e.target.value);
  };

  const onNameChange = (e) => {
    setName(e.target.value);
  };

  const onLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const onPhoneChange = (value) => {
    setPhone(value);
  };

  const onPhoneCountryChange = (country, countryCallingCode) => {
    setPhoneCountry(country);
    setPhoneCountryCallingCode(countryCallingCode);
  };

  const onSignUpPress = (e) => {
    signUp(email, name, lastName, phone, agree);
  };

  const onAgreeChanged = (e) => {    
    setAgree(!agree);
  };

  // #endregion

  // #region Methods

  const signUp = async (email, name, lastName, phone, agree) => {
    resetAlerts();
    let errorMessages = [];

    const data = {
      email,
      name,
      lastName,
      agree
    }

    console.log('DATA', data, phone);

    if (!isPossiblePhoneNumber(phone? phone : '')) {
      errorMessages.push('El Número telefónico no es válido.');
      setErrorMessages(errorMessages);
      setAlertWarningIsVisible(true);
    }

    validationSchema.validate(data, {abortEarly: false})
      .then(valid => {        
        if (errorMessages.length > 0) {
          setErrorMessages(errorMessages);
          setAlertWarningIsVisible(true);
        } else {          
          fetch(`${process.env.REACT_APP_API_URL}/lagranvida/v1/subscription/register`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              email,
              name,
              lastName,
              areaCode: 0,
              phone
            }),
          }).then(response => response.json()).then(data => {
            if (data.responseCode === 'OK') {
              nav.navigate(Paths.views.HOME);
            } else {
              setErrorMessages(['Error al registrar datos.'])
              setAlertWarningIsVisible(true);
            }
          }, (error) => {
            setErrorMessages(['Error al registrar.'])
            setAlertWarningIsVisible(true);
          });
        }
      })
      .catch(error => {
        console.log('ERROR', error.errors);
        errorMessages = [...error.errors, ...errorMessages];
        setErrorMessages(errorMessages);
        setAlertWarningIsVisible(true);
      });
  }

  const resetAlerts = () => {
    setErrorMessages('');
    setAlertWarningIsVisible(false);
  };

  // #endregion

  // #region Templates

  const signInTemplate = (
    <>
      <Container
        fluid
        className='align-items-end'
        style={{
          background: `url(${primaryAbstractBackgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
      >
        <div className='row p-4'>
          <div className='col-12 offset-sm-1 col-sm-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6 offset-xl-4 col-xl-4'>
            <div className='row'>
              <div className='col-12 text-center'>
                <img
                  src={Logo}
                  height="80"
                  className="d-inline-block align-top my-5 w-100"
                  alt="La Grand Vida logo"
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <h1 className='app-neutral-black-text app-pharma-bold-font text-uppercase text-center'>
                  Registro
                </h1>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <Alert
                  key='warning'
                  variant='warning'
                  hidden={!alertWarningIsVisible}
                >
                  {
                    (errorMessages &&  errorMessages.length > 0) && (
                      errorMessages.map((error, index) => {
                        return (
                          <>
                            <p>
                              {error}
                            </p>
                          </>
                        );
                      })
                    )
                  }
                </Alert>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <Row className="mb-3">
                  <Col>
                    <Form.Group
                      as={Col}
                      sm="12"
                      controlId="validationFormikEmail"
                    >
                      <Form.Label className='app-montserrat-semi-bold-font'>
                        Correo electrónico
                      </Form.Label>
                      <InputGroup hasValidation>
                        {/* <InputGroup.Text className='app-secondary-bg app-neutral-white-text'>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text> */}
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Correo electrónico"
                          className='app-montserrat-regular-font app-neutral-grey-1-text'
                          value={email}
                          onChange={onEmailChange}
                          isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Form.Group
                      as={Col}
                      sm="12"
                      controlId="validationFormikName"
                    >
                      <Form.Label className='app-montserrat-semi-bold-font'>
                        Nombre
                      </Form.Label>
                      <InputGroup hasValidation>
                        {/* <InputGroup.Text className='app-secondary-bg app-neutral-white-text'>
                                <FontAwesomeIcon icon={faKey} />
                              </InputGroup.Text> */}
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Nombre"
                          className='app-montserrat-regular-font app-neutral-grey-1-text'
                          value={name}
                          onChange={onNameChange}
                          isInvalid={!!errors.name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Form.Group
                      as={Col}
                      sm="12"
                      controlId="validationFormikLastName"
                    >
                      <Form.Label className='app-montserrat-semi-bold-font'>
                        Apellido
                      </Form.Label>
                      <InputGroup hasValidation>
                        {/* <InputGroup.Text className='app-secondary-bg app-neutral-white-text'>
                                <FontAwesomeIcon icon={faKey} />
                              </InputGroup.Text> */}
                        <Form.Control
                          type="text"
                          name="lastName"
                          placeholder="Apellido"
                          className='app-montserrat-regular-font app-neutral-grey-1-text'
                          value={lastName}
                          onChange={onLastNameChange}
                          isInvalid={!!errors.lastName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.lastName}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <Form.Group
                      as={Col}
                      sm="12"
                      controlId="validationFormikPhone"
                    >
                      <Form.Label className='app-montserrat-semi-bold-font'>
                        Número telefónico
                      </Form.Label>
                      <PhoneInput
                        international
                        placeholder="Número telefónico"
                        className='app-sign-up-view-phone-input app-montserrat-regular-font app-neutral-grey-1-text'
                        countryCallingCodeEditable={false}
                        defaultCountry="MX"
                        value={phone}
                        onChange={onPhoneChange}
                        onCountryChange={onPhoneCountryChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <Form.Check                
                      className='app-montserrat-semi-bold-font'  
                      onChange={onAgreeChanged}
                      checked={agree}
                      type='checkbox'
                      label='Estoy deacuerdo con los Términos y Condiciones'
                    />                    
                  </Col>
                </Row>

                <Button
                  type="button"
                  className='app-accent-button text-uppercase'
                  onClick={onSignUpPress}
                >
                  Registrarme
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article className='app-sign-up-view app-has-navbar'>
        <section>
          {signInTemplate}
        </section>
      </article>      
    </>
  );
}

export default SignUpView;