
export const sizes = {
    EXTRA_SMALL: 'xs',
    SMALL: 'sm',
    MEDIUM: 'md',
    LARGE: 'lg',
    EXTRA_LARGE: 'xl',
    EXTRA_EXTRA_LARGE: 'xxl'
};

export const breakpoints = {
    EXTRA_SMALL: 0,
    SMALL: 576,
    MEDIUM: 768,
    LARGE: 992,
    EXTRA_LARGE: 1200,
    EXTRA_EXTRA_LARGE: 1400
};