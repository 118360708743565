import { Container } from 'react-bootstrap';
import socialFacebookIcon from '../../../../assets/images/social/facebook-icon.png';
import socialInstagramIcon from '../../../../assets/images/social/instagram-icon.png';
import socialLinkedinIcon from '../../../../assets/images/social/linkedin-icon.png';
import socialYoutubeIcon from '../../../../assets/images/social/youtube-icon.png';

function SocialNetworksBox() {

  // #region Templates

  const socialTemplate = (
    <>
      <Container fluid className='text-end p-3'>
        <a href='https://www.facebook.com/profile.php?id=61562758961253' target='blank'>
          <img
            src={socialFacebookIcon}
            className='m-1'
            alt="Facebook icon"
            width={25}
          />
        </a>
        <a href='https://www.instagram.com/lagranvida.mx/' target='blank'>
          <img
            src={socialInstagramIcon}
            className='m-1'
            alt="Instagram icon"
            width={25}
          />
        </a>
        <a href='https://www.linkedin.com/company/la-gran-vida' target='blank'>
          <img
            src={socialLinkedinIcon}
            className='m-1'
            alt="Linkedin icon"
            width={25}
          />
        </a>
        <a href='https://www.youtube.com/@LAGRANVIDAMX' target='blank'>
          <img
            src={socialYoutubeIcon}
            className='m-1'
            alt="Youtube icon"
            width={25}
          />
        </a>
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article>
        <section>
          {socialTemplate}
        </section>
      </article>
    </>
  );
}

export default SocialNetworksBox;