import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../../../hooks/useAuth';
import { useNav } from '../../../../../../hooks/useNav';
import ModulesCard from './ModulesCard/ModulesCard';
import ProgressCard from './ProgressCard/ProgressCard';
import * as Paths from "../../../../../../constants/paths";

function MyProgramBox({
  program
}) {

  // #region Vars and Consts

  const nav = useNav();
  const { id } = useParams();
  const { user, logout } = useAuth();  
  // const [program, setProgram] = useState(null);

  // #endregion

  // #region Events

  // useEffect(() => {
  //   getProgram(user.userId, id);
  // }, []);

  const onModuleEntrancePress = () => {
    enterModule(user.userId, program.programId);
  };

  // #endregion

  // #region Methods

  const enterModule = (userId, programId) => {
    fetch(`${process.env.REACT_APP_API_URL}/lagranvida/v1/userProgram/getCurrentModule?userId=${userId}&programId=${programId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      }
    }).then(response => response.json()).then(data => {
      if (data.responseCode === 'OK') {
        console.info('Module ID entrance:', data);
        const moduleId = data.responseObject;
        goToProgramModule(programId, moduleId);        
      } else if (data.status === 403) {
        // Session expired
        logout();
      }
    }, (error) => {
      console.error('Error getting keep growing programs', error);
      // TODO: Show error message to the user
    });
  };

  const goToProgramModule = (programId, moduleId) => {
    nav.navigate(
      Paths.views.user.MY_PROGRAM_MODULE,
      null,
      {
        ':programId': programId,
        ':moduleId': moduleId
      }
    );    
  };

  // #endregion

  // #region Templates

  const imageTemplate = (
    <>
      {
        program && (
          <Row>
            <Col className='p-4'>
              <img
                className='w-100 shadow'
                style={{
                  borderRadius: '2rem',
                }}
                src={program.imageUrl}
                alt={program.title}
              />
            </Col>
          </Row>
        )
      }
    </>
  );

  const progressTemplate = (
    <>
      {
        program && (
          <Row className='h-100'>
            <Col className='p-4'>
              <ProgressCard
                progress={program.percentageProgress}
                completed={program.completed}
                buttonLabel={program.completed ? 'Entrar' : 'Continuar'}
                onButtonPress={onModuleEntrancePress}
              />    
            </Col>
          </Row>        
        )
      }
    </>
  );

  const modulesTemplate = (
    <>
      {
        program && (
          <Row className='h-100'>
            <Col className='p-4'>       
              <ModulesCard 
                programId={id}
                modules={program.modules}
              />
            </Col>
          </Row>
        )
      }
    </>
  );

  // #endregion

  return (
    <>
      {
        program && (
          <Container>
            <Row>
              <Col xs={12} md={5}>
                <Row>
                  <Col xs={12} md={12}>
                    {imageTemplate}
                  </Col>
                  <Col xs={12} md={12}>
                    {progressTemplate}
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={7}>
                {modulesTemplate}
              </Col>
            </Row>
          </Container>
        )
      }     
    </>
  );
}

export default MyProgramBox;
